import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { format } from 'date-fns';
import addEditBillingCycle from './addEditBillingCycle';
export default {
  name: 'BillingCycle',
  components: {
    ModelSelect,
    DatePicker,
    addEditBillingCycle
  },
  watch: {
    currentPage: function() {
      this.getBillingCycleList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBillingCycleList();
    }
  },
  data() {
    return {
      billingCycleHdrId: null,
      showBillingCycleModal: false,
      loader: false,
      unsubscribe: null,
      totalRows: null,
      payload: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      billingCycle: null,
      vsetCode: null,
      showValueSetModal: false,
      setTimeVsetCode: null,
      startDate: null,
      duration: null,
      uom: {
        value: null,
        text: null
      },
      paymentTerm: {
        value: null,
        text: null
      },
      paymentTermList: [],
      penaltyTerm: {
        value: null,
        text: null
      },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      penaltyTermList: [],
      billingCycleData: [],
      billingCycleDataFields: [
        {
          key: 'le_name'
        },
        {
          key: 'billing_cycle_name',
          label: 'Billing Cycle'
        },
        {
          key: 'cycle_start_date',
          label: 'Start Date'
        },
        {
          key: 'duration'
        },
        {
          key: 'uom_meaning',
          label: 'UOM'
        },
        {
          key: 'payment_term',
          label: 'Payment'
        },
        {
          key: 'penalty_payment_term',
          label: 'Penalty'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.billingCycleHdrId = 0;
          this.showBillingCycleModal = true;
        }
        if (actionName === 'download' && !this.showBillingCycleModal) 
        {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'masterData/getMasterBillingData',
            'billing-cycle',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getBillingCycleList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        billing_cycle_name: this.billingCycle,
        cycle_start_date: this.startDate
          ? format(new Date(this.startDate), 'dd-MMM-yyyy')
          : null,
        duration: this.duration,
        uom: this.uom.value,
        payment_term: this.paymentTerm.value,
        panelty_payment_term: this.penaltyTerm.value,
        lename: this.selectedLegalEntity.value,
        currency_code: null
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getMasterBillingData', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.billingCycleData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = item.org_id;
        this.selectedLegalEntity.text = item.org_name;
      } else if (this.vsetCode === 'FMS_PAYMENT_TERM') {
        this.paymentTerm.value = item.payment_term_id;
        this.paymentTerm.text = item.payment_term_name;
      } else if (this.vsetCode === 'UOM') {
        this.uom.value = item.value_code;
        this.uom.text = item.value_meaning;
      } else if (this.vsetCode === 'FMS_PENALTY_TERM') {
        this.penaltyTerm.value = item.payment_term_id;
        this.penaltyTerm.text = item.payment_term_name;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, valuseSetDtlId) {
      this.parent_value_set_id = valuseSetDtlId;
      if (vsetCode === 'FMS_PENALTY_TERM') {
        this.vsetCode = 'FMS_PENALTY_TERM';
      } else {
        this.vsetCode = vsetCode;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName:
            vsetCode === 'FMS_PENALTY_TERM' ? 'FMS_PAYMENT_TERM' : vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    opendDependentUom(vsetCode) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.openValueSetModal('UOM', findTimeObj.value_set_dtl_id);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showhideBillingModal(flag) {
      this.showBillingCycleModal = flag;
    },
    rowSelected(item) {
      this.billingCycleHdrId = item.billing_cycle_hdr_id;
      this.showBillingCycleModal = true;
    },
    clearSearch() {
      this.duration = null;
      this.paymentTerm.value = null;
      this.paymentTerm.text = null;
      this.uom.value = null;
      this.uom.text = null;
      this.penaltyTerm.value = null;
      this.penaltyTerm.text = null;
      this.startDate = null;
      this.billingCycle = null;
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.billingCycleData = [];
      this.totalRows = null;
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.selectedLegalEntity.value = null;
        this.selectedLegalEntity.text = null;
      } else if (vsetCode === 'FMS_PAYMENT_TERM') {
        this.paymentTerm.value = null;
        this.paymentTerm.text = null;
      } else if (vsetCode === 'UOM_CLASS') {
        this.uom.value = null;
        this.uom.text = null;
      } else if (vsetCode === 'FMS_PENALTY_TERM') {
        this.penaltyTerm.value = null;
        this.penaltyTerm.text = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
