import { required, numeric } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addEditBillingCycle',
  components: { ModelSelect, DatePicker },
  props: ['billingCycleHdrId'],
  data() {
    return {
      parent_value_set_id: null,
      uomClassObj: null,
      tablePrameter: null,
      setTimeVsetCode: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      legalEntityList: [],
      billingForm: {
        billing_cycle_hdr_id: 0,
        billing_cycle_name: null,
        currency_id: null,
        cycle_start_date: null,
        duration: null,
        le_id: null,
        payment_term_id: null,
        penalty_payment_term_id: null,
        uom_vset: null
      },
      selectedCurrency: {
        id: 68,
        name: 'INR'
      },
      selectedUOM: {
        value: null,
        text: null
      },
      selectedPaymentTerm: {
        value: null,
        text: null
      },
      selectedPenaltyTerm: {
        value: null,
        text: null
      },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      ruleForm: {
        rule_name: null
      },
      startDate: null
    };
  },
  validations: {
    billingForm: {
      billing_cycle_name: {
        required
      },
      duration: { numeric }
    }
  },
  mounted() {
    this.getBillingCycleDetail();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditBillingCycle();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {

          this.eventBus.$emit('commonUpload', { id: this.billingForm.billing_cycle_hdr_id});

        }
      }
    });
  },
  methods: {
    addEditBillingCycle() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          billing_cycle_hdr_id: this.billingForm.billing_cycle_hdr_id,
          billing_cycle_name: this.billingForm.billing_cycle_name,
          currency_id: this.selectedCurrency.id,
          cycle_start_date: this.billingForm.cycle_start_date
            ? format(new Date(this.billingForm.cycle_start_date), 'dd-MMM-yyyy')
            : null,
          duration: this.billingForm.duration,
          le_id: parseInt(this.selectedLegalEntity.value),
          payment_term_id: this.selectedPaymentTerm.value,
          penalty_payment_term_id: this.selectedPenaltyTerm.value,
          uom_vset: this.selectedUOM.value
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditMasterBills', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.billingForm.billing_cycle_hdr_id =
                response.data.data.billing_cycle_hdr_id;
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getBillingCycleDetail() {
      if (this.billingCycleHdrId) {
        this.billingForm.billing_cycle_hdr_id = this.billingCycleHdrId;
        this.loader = true;
        this.$store
          .dispatch('masterData/getBillingCycleData', this.billingCycleHdrId)
          .then(response => {
            this.editMode = false;
            this.loader = false;
            if (response.status === 200) {
              this.billingForm = response.data.data;
              const resp = response.data.data;
              this.billingForm.cycle_start_date = new Date(
                response.data.data.cycle_start_date
              );
              this.selectedLegalEntity = {
                value: resp.le_id,
                text: resp.le_name
              };
              this.selectedPaymentTerm = {
                text: resp.payment_term,
                value: resp.payment_term_id
              };
              this.selectedPenaltyTerm = {
                value: resp.penalty_payment_term_id,
                text: resp.penalty_payment_term
              };
              this.selectedUOM = {
                value: resp.uom_vset,
                text: resp.uom_meaning
              };
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    opendDependentUom(vsetCode) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = item.org_id;
        this.selectedLegalEntity.text = item.org_name;
      } else if (this.vsetCode === 'FMS_PAYMENT_TERM') {
        this.selectedPaymentTerm.value = item.payment_term_id;
        this.selectedPaymentTerm.text = item.payment_term_name;
      } else if (this.vsetCode === 'UOM') {
        this.selectedUOM.value = item.value_code;
        this.selectedUOM.text = item.value_meaning;
      } else if (this.vsetCode === 'FMS_PENALTY_TERM') {
        this.selectedPenaltyTerm.value = item.payment_term_id;
        this.selectedPenaltyTerm.text = item.payment_term_name;
      }
    },
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = null;
      if (vsetCode === 'FMS_PENALTY_TERM') {
        this.vsetCode = 'FMS_PENALTY_TERM';
      } else {
        this.vsetCode = vsetCode;
      }
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName:
            vsetCode === 'FMS_PENALTY_TERM' ? 'FMS_PAYMENT_TERM' : vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected() {},
    clearVsetValues(vsetCode){
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.selectedLegalEntity.value = null;
        this.selectedLegalEntity.text = null;
      } else if (vsetCode === 'FMS_PAYMENT_TERM') {
        this.selectedPaymentTerm.value = null;
        this.selectedPaymentTerm.text = null;
      } else if (vsetCode === 'UOM_CLASS') {
        this.selectedUOM.value = null;
        this.selectedUOM.text = null;
      } else if (vsetCode === 'FMS_PENALTY_TERM') {
        this.selectedPenaltyTerm.value = null;
        this.selectedPenaltyTerm.text = null;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.eventBus.$off('groupDetail');
    this.unsubscribe();
  }
};
